<template>
  <input v-model="radius" />
  <button>apply</button>
  <div id="map"></div>

</template>

<script>
import "leaflet/dist/leaflet.css"
import L from "leaflet";

export default {
  name: "map",
  map: null,
  latlng: null,

  data() {

    return {
      radius : 200,
      center: [37, 7749, -122, 4194]
    }
  },

  methods: {
    setupLeafletMap: function () {
      this.map = L.map("map").setView([35.7219, 51.3347], 12);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '© OpenStreetMap'
      }).addTo(this.map);
      const myIcon = L.icon({
        iconUrl: 'https://icon-library.com/images/maps-pin-icon/maps-pin-icon-15.jpg',
        iconSize: [38, 95],
        iconAnchor: [22, 94],
        popupAnchor: [-3, -76],
        shadowUrl: 'https://icon-library.com/images/maps-pin-icon/maps-pin-icon-15.jpg',
        shadowSize: [0, 0],
        shadowAnchor: [22, 94]
      });
      L.marker([35.7219, 51.3347], {icon: myIcon}).addTo(this.map)
      this.map.on('click', (e) => {this.latlng = e.latlng;this.drawCircle()});
    },
    drawCircle: function () {
      console.log(this.rrr)
      L.circle(this.latlng, {radius: this.radius}).addTo(this.map)
    },
    setMarker: function (e) {
      // alert(e.latlng)

      L.marker([35.7219, 51.3389], {
        icon: L.icon({
          iconUrl: 'https://icon-library.com/images/maps-pin-icon/maps-pin-icon-15.jpg',
          iconSize: [38, 95],
          iconAnchor: [22, 94],
          popupAnchor: [-3, -76],
          shadowUrl: 'https://icon-library.com/images/maps-pin-icon/maps-pin-icon-15.jpg',
          shadowSize: [0, 0],
          shadowAnchor: [22, 94]
        })
      }).addTo(this.map)
    }
  },
  mounted() {
    this.setupLeafletMap();
  },
}
</script>

<style scoped>

#map {
  height: 750px;
}
</style>
